.modal-box {
    border-radius: 8px;
  }
  
  .modal-link {
    color: #1976d2;
    word-break: break-all;
  }
  
  .modal-button {
    margin-top: 16px;
  }
  