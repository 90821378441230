html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

header {
  background-color: #007bff;
  color: white;
  padding: 1rem;
  text-align: center;
}

footer {
  background-color: #007bff;
  color: white;
  padding: 1rem;
  text-align: center;
  margin-top: auto;
}

.content {
  flex: 1;
  padding: 1rem;
  padding-bottom: 1rem;
}
